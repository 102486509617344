import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import config from '../config';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import Modal from 'react-modal';
import '../assets/css/SubscriptionPage.css';

// Import images directly
import basicIcon from '../images/basic-icon.svg';
import proIcon from '../images/pro-icon.svg';
import enterpriseIcon from '../images/enterprise-icon.svg';
import checkmarkIcon from '../images/checkmark.svg';

// Configure the modal to attach to the root element
Modal.setAppElement('#root');

const SubscriptionPage = () => {
    const { user, logout } = useAuth();
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [subscriptionSuccessMessage, setSubscriptionSuccessMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            setIsLoading(false);
        }
    }, [user]);

    if (user?.subscription_status === 'active') {
        return <Navigate to="/" />;
    }

    const initialOptions = {
        "client-id": config.clientId_paypal,
        currency: "USD",
        intent: "subscription",
        vault: true,
    };

    const handleSubscriptionSuccess = async (data, planId, userId) => {
        try {
            const tokenResponse = await axios.post(`${config.apiHost}/api/auth/token`, {
                email: config.default_user_email,
                password: config.defaultUserPassword,
            });
            const tokenData = tokenResponse.data;

            const response = await axios.post(`${config.apiHost}/api/update-subscription-status`, {
                subscriptionId: data.subscriptionID,
                userId: userId,
                planId: planId,
                subscribe_id: data.subscriptionID,
                status: 'active',
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${tokenData.access_token}`,
                }
            });

            if (response.status === 200) {
                setSubscriptionSuccessMessage('Subscription updated successfully');
                setIsModalOpen(true);
            } else {
                alert('Failed to update subscription status');
            }
        } catch (error) {
            console.error('Error updating subscription status:', error);
        }
    };

    const closeModalAndRedirect = () => {
        setIsModalOpen(false);
        logout();
    };

    if (isLoading || !user) {
        return <div>Loading...</div>;
    }

    return (
        <PayPalScriptProvider options={initialOptions}>
            <main>
                <section id="hero_pricing">
                    <div className="subscription-container">
                        <div className="hero-pricing-wrapper">
                            <h1>Grant Pricing & Membership Information</h1>
                            <p>
                                Explore our extensive database, access AI-powered assistance,
                                and connect with expert consultants. Choose the plan that best
                                fits your needs and start benefiting from our comprehensive
                                services today.
                            </p>
                        </div>
                    </div>
                </section>

                <section id="pricing_plans">
                    <div className="subscription-container">
                        <h2>Our Pricing Plans</h2>
                        <div className="row">
                            {/* Basic Plan */}
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="pricing-card">
                                    <img src={basicIcon} alt="basic" />
                                    <h3>Basic</h3>
                                    <p>
                                        Access to our grant database and AI recommendations. Perfect for individuals and small organizations just starting their grant search.
                                    </p>
                                    <div className="separator-card"></div>
                                    <ul className="pricing-features">
                                        <li><img src={checkmarkIcon} alt="check" /> Access to basic database searches</li>
                                        <li><img src={checkmarkIcon} alt="check" /> Limited AI assistance</li>
                                        <li><img src={checkmarkIcon} alt="check" /> Email support</li>
                                    </ul>
                                    <div className="plans-footer">
                                        <div className="price">$19<span>/mo</span></div>
                                        <PayPalButtons
                                            style={{ layout: "vertical" }}
                                            createSubscription={(data, actions) => {
                                                return actions.subscription.create({
                                                    plan_id: config.plan_1_paypal
                                                });
                                            }}
                                            onApprove={(data, actions) => {
                                                handleSubscriptionSuccess(data, config.plan_1_paypal, user.id);
                                            }}
                                            onError={(err) => {
                                                console.error('Subscription failed: ', err);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Pro Plan */}
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="pricing-card">
                                    <img src={proIcon} alt="pro" />
                                    <h3>Pro</h3>
                                    <p>
                                        All Basic features plus premium support and advanced search tools. Ideal for organizations with ongoing funding needs.
                                    </p>
                                    <div className="separator-card"></div>
                                    <ul className="pricing-features">
                                        <li><img src={checkmarkIcon} alt="check" /> Unlimited database searches</li>
                                        <li><img src={checkmarkIcon} alt="check" /> Enhanced AI assistance</li>
                                        <li><img src={checkmarkIcon} alt="check" /> Priority email support</li>
                                        <li><img src={checkmarkIcon} alt="check" /> Access to community forums</li>
                                    </ul>
                                    <div className="plans-footer">
                                        <div className="price">$45<span>/mo</span></div>
                                        <PayPalButtons
                                            style={{ layout: "vertical" }}
                                            createSubscription={(data, actions) => {
                                                return actions.subscription.create({
                                                    plan_id: config.plan_2_paypal
                                                });
                                            }}
                                            onApprove={(data, actions) => {
                                                handleSubscriptionSuccess(data,  config.plan_2_paypal, user.id);
                                            }}
                                            onError={(err) => {
                                                console.error('Subscription failed: ', err);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Enterprise Plan */}
                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="pricing-card">
                                    <img src={enterpriseIcon} alt="enterprise" />
                                    <h3>Enterprise</h3>
                                    <p>
                                        Comprehensive access including dedicated consultant support and custom AI recommendations. Best for large organizations and enterprises.
                                    </p>
                                    <div className="separator-card"></div>
                                    <ul className="pricing-features">
                                        <li><img src={checkmarkIcon} alt="check" /> Unlimited database searches</li>
                                        <li><img src={checkmarkIcon} alt="check" /> Advanced AI assistance with personalized insights</li>
                                        <li><img src={checkmarkIcon} alt="check" /> 24/7 support via phone and email</li>
                                        <li><img src={checkmarkIcon} alt="check" /> Exclusive access to expert consultants</li>
                                        <li><img src={checkmarkIcon} alt="check" /> Early access to new features</li>
                                    </ul>
                                    <div className="plans-footer">
                                        <div className="price">Contact us for pricing</div>
                                        <a href="#" className="btn-start">Get Started</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Success Modal */}
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModalAndRedirect}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            borderRadius: '10px',
                            padding: '20px',
                            textAlign: 'center',
                            zIndex: '10000',
                            position: 'fixed',
                        },
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.85)',
                            zIndex: '9999',
                        }
                    }}
                >
                    <h2>{subscriptionSuccessMessage}</h2>
                    <p>Your subscription has been successfully updated.</p>
                    <button
                        onClick={closeModalAndRedirect}
                        style={{
                            padding: '10px 20px',
                            borderRadius: '5px',
                            backgroundColor: '#4A90E2',
                            color: '#fff',
                            border: 'none',
                            cursor: 'pointer',
                            marginTop: '20px',
                            fontSize: '16px',
                        }}
                    >
                        Please log in again to continue.
                    </button>
                </Modal>
            </main>
        </PayPalScriptProvider>
    );
};

export default SubscriptionPage;
