import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/logogrants.webp';
import '../assets/css/footer.css'; // Estilo específico para o footer

const Footer = () => {
  const SocialLinks = () => (
    <div className="social-media">
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} />
      </a>
      <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} />
      </a>
      <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTwitter} />
      </a>
    </div>
  );

  return (
    <footer id="footer" className="main-footer">
      <div className="footer-container">
        <div className="footer-left">
          <div className="footer-logo">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
            <p>
              Explore our extensive database, get AI assistance, and connect with expert consultants.
            </p>
          </div>
        </div>
        <div className="footer-menu">
          <div className="footer-links">
            <Link to="/">Home</Link>
            <Link to="/about">About Us</Link>
            <Link to="/grant-database">Grant Database</Link>
            <Link to="/ai-assistance">AI Assistance</Link>
          </div>
          <div className="footer-links">
            <Link to="/consultants">Consultants</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/contact">Contact Us</Link>
          </div>
          <div className="footer-links">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms of Service</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/support">Support</Link>
          </div>
        </div>
      </div>
      <div className="copyright-wrapper">
        <div className="copyright-text">
          <p>©2024 Grants. All Rights Reserved</p>
        </div>
        <SocialLinks />
      </div>
    </footer>
  );
};

export default Footer;
