import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext.js';
import './UserPage.css';  // Importe o arquivo CSS
import config from '../config';

const UserPage = () => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const { token, refreshAccessToken } = useAuth();

    useEffect(() => {
        const fetchUser = async () => {
            try {
              //  console.log('Fetching user data with token:', token);
                const response = await axios.get('http://localhost:8000/api/auth/me', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
              //  console.log('User data fetched:', response.data);
                setUser(response.data);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                   // console.log('Token expired, refreshing token...');
                    try {
                        await refreshAccessToken();
                        // Retry fetching user data after refreshing the token
                        const newToken = localStorage.getItem('token');
                     //   console.log('Retrying fetch with new token:', newToken);
                        const response = await axios.get(`${config.apiHost}/api/auth/me`, {

                            
                            headers: {
                                Authorization: `Bearer ${newToken}`,
                            },
                        });
                       // console.log('User data fetched after token refresh:', response.data);
                        setUser(response.data);
                    } catch (refreshError) {
                        console.error('Failed to refresh access token', refreshError);
                        setError('Failed to refresh access token');
                    }
                } else {
                    console.error('Failed to fetch user data', err);
                    setError('Failed to fetch user data');
                }
            }
        };

        if (token) {
            fetchUser();
        } else {
          //  console.log('Token is not available.');
        }
    }, [token, refreshAccessToken]);

    if (error) {
        return <div className="error">{error}</div>;
    }

    if (!user) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <div className="user-page">
            <h1 className="user-title">User Information</h1>
            <p className="user-info">Email: {user.email}</p>
            <p className="user-info">Created At: {new Date(user.created_at).toLocaleString()}</p>
        </div>
    );
};

export default UserPage;
