// src/components/GrantsChatbot.js

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import BotImage from '../assets/Grant_photo.webp';
import LoadingIcon from '../assets/logogrants.png';
import '../assets/css/chatbot.css';
import config from '../config';

const GrantsChatbot = ({ grantId, all, gpt_response, initialMessage }) => {
    const [query, setQuery] = useState('');
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isTyping, setIsTyping] = useState(false);
    const [cancelTokenSource, setCancelTokenSource] = useState(null);
    const [typingInterval, setTypingInterval] = useState(null);
    const [token, setToken] = useState(localStorage.getItem('access_token') || null);
    const chatBoxRef = useRef(null);

    useEffect(() => {
        document.title = "AI";
    }, []);

    useEffect(() => {
        const welcomeMessage = { role: 'assistant', content: 'Hello, how can I assist you today?' };
        setMessages([welcomeMessage]);

        // Trigger initial message if provided
        if (initialMessage) {
            handleSend(initialMessage);
        }
    }, [initialMessage]);

    // Função para obter o token usando email e senha do config
    const fetchToken = async () => {
        try {
            const response = await axios.post(
                `${config.apiHost}/api/auth/token`,
                {
                    email: config.default_user_email,
                    password: config.defaultUserPassword,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const { access_token } = response.data;
            localStorage.setItem('access_token', access_token);
            setToken(access_token);
            return access_token;
        } catch (err) {
            console.error('Erro ao obter token:', err.response?.data || err);
            setError('Falha na autenticação. Por favor, tente novamente.');
            return null;
        }
    };

    const handleSend = async (customQuery = null) => {
        const userQuery = customQuery || query;
        if (!userQuery.trim()) return;

        const userMessage = { role: 'user', content: userQuery };
        setMessages(prevMessages => [...prevMessages, userMessage]);
        setQuery('');
        setLoading(true);
        setError(null);

        const source = axios.CancelToken.source();
        setCancelTokenSource(source);

        try {
            let currentToken = token;

            // Se não houver token, tentar buscar um novo
            if (!currentToken) {
                currentToken = await fetchToken();
                if (!currentToken) {
                    // Erro já foi definido em fetchToken
                    setLoading(false);
                    return;
                }
            }

            // Realizar a requisição POST ao endpoint /api/conversation com o Bearer Token
            const response = await axios.post(
                `${config.apiHost}/api/conversation`,
                {
                    answer: grantId ? `${userQuery} grants id ${grantId}` : `${userQuery}`,
                    all: all,
                    gpt_process: gpt_response
                },
                {
                    headers: {
                        Authorization: `Bearer ${currentToken}`, // Incluir o token no header Authorization
                        'Content-Type': 'application/json',
                    },
                    cancelToken: source.token
                }
            );

            console.log(response);
            const botMessageContent = response.data.response;

            if (botMessageContent) {
                const botMessage = { role: 'assistant', content: '' };
                setMessages(prevMessages => [...prevMessages, botMessage]);
                setIsTyping(true);
                simulateTyping(botMessageContent, botMessage);
            } else {
                throw new Error('Invalid response from server');
            }
        } catch (err) {
            if (axios.isCancel(err)) {
                setError('Request canceled.');
            } else if (err.response && err.response.status === 401) {
                // Token inválido ou expirado
                setError('Sua sessão expirou. Por favor, faça login novamente.');
                // Opcional: Remover token do localStorage
                localStorage.removeItem('access_token');
                setToken(null);
            } else {
                setError('Erro ao buscar dados.');
                console.error('Error fetching AI response:', err);
            }
            setLoading(false);
            setIsTyping(false);
        }
    };

    const simulateTyping = (content, botMessage) => {
        let index = 0;
        setLoading(false);
        const interval = setInterval(() => {
            if (index < content.length) {
                botMessage.content += content[index];
                setMessages(prevMessages => [...prevMessages]);
                index++;
            } else {
                clearInterval(interval);
                setIsTyping(false);
            }
        }, 5);  // Ajuste a velocidade da simulação de digitação conforme necessário
        setTypingInterval(interval);
    };

    const handleCancel = () => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel();
            setCancelTokenSource(null);
            setLoading(false);
            setIsTyping(false);
            clearInterval(typingInterval);
            setTypingInterval(null);
        }
    };

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages, loading]);

    const defaultSuggestions = [
        'List grants open this month?',
        'What is a grant?',
        'List grants open today?',
        'Show me available grants?',
        'What grant opportunities are available for agency code DOE-01?'
    ];

    const grantSpecificSuggestions = [
        'What are the eligibility criteria for this grant?',
        'Can you provide details on the application process?',
        'What is the funding range for this grant?',
        'What are the important deadlines?',
        'Are there any specific requirements for this grant?'
    ];

    const suggestions = grantId ? grantSpecificSuggestions : defaultSuggestions;

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>
                {grantId ? (
                    <>Have questions about this grant? <span style={styles.highlightedText1}>AI</span> <span style={styles.highlightedText2}>Assistance</span> is here to help!</>
                ) : (
                    <>Grants <span style={styles.highlightedText1}>AI</span> <span style={styles.highlightedText2}>Assistance</span> for Your Needs</>
                )}
            </h1>

            <div ref={chatBoxRef} style={styles.chatBox}>
                {messages.map((msg, index) => (
                    <div key={index} style={{ ...styles.messageContainer, justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start' }}>
                        {msg.role === 'assistant' && (
                            <img
                                src={BotImage}
                                alt="Bot"
                                style={styles.botImage}
                            />
                        )}
                        <div style={{
                            ...styles.messageBubble,
                            backgroundColor: msg.role === 'user' ? '#0078FF' : '#F1F1F1',
                            color: msg.role === 'user' ? 'white' : 'black'
                        }}>
                            <ReactMarkdown components={{ li: ({ ...props }) => <li className="messageItem" {...props} /> }}>
                                {msg.content}
                            </ReactMarkdown>
                        </div>
                    </div>
                ))}
                {loading && (
                    <div style={styles.messageContainer}>
                        <img src={BotImage} alt="Bot" style={styles.botImage} />
                        <div style={{ ...styles.messageBubble, ...styles.loadingMessage }}>
                            <img src={LoadingIcon} alt="Loading" style={styles.loadingIcon} className="loading" />
                        </div>
                    </div>
                )}
                {error && <p style={styles.error}>{error}</p>}
            </div>
            <div style={styles.inputContainer}>
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Type your message"
                    style={styles.input}
                    onKeyDown={(e) => e.key === 'Enter' && !loading && !isTyping && handleSend()}
                    disabled={loading || isTyping}
                />
                <button onClick={() => handleSend()} style={{ ...styles.button, opacity: (loading || isTyping) ? 0.5 : 1 }} disabled={loading || isTyping}>Send</button>
                {loading || isTyping ? <button onClick={handleCancel} style={styles.cancelButton}>Cancel</button> : null}
            </div>
            <div style={styles.suggestionsContainer}>
                {suggestions.map((suggestion, index) => (
                    <div key={index} style={styles.suggestionBubble} onClick={() => handleSend(suggestion)}>
                        {suggestion}
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        maxWidth: '800px',
        maxHeight: '880px',
        margin: '0 auto ',
        marginTop: '100px',
        marginBottom: '100px',
        textAlign: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 4px 1px',
        fontFamily: "'Roboto', sans-serif",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    header: {
        fontSize: '26px',
        fontWeight: 'bold',
        lineHeight: '1.2',
        color: '#333',
        fontFamily: 'Arial, sans-serif'
    },
    highlightedText1: {
        color: '#FF6F61'
    },
    highlightedText2: {
        color: '#00BFFF'
    },
    chatBox: {
        flex: 1,
        border: '1px solid #DDDDDD',
        padding: '10px',
        borderRadius: '10px',
        overflowY: 'scroll',
        maxHeight: 'calc(100% - 120px)',
        backgroundColor: '#F9F9F9'
    },
    messageContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'
    },
    messageBubble: {
        display: 'inline-block',
        padding: '8px',
        borderRadius: '15px',
        maxWidth: '80%',
        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
        fontSize: '12px'
    },
    botImage: {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        marginRight: '10px'
    },
    loadingMessage: {
        display: 'flex',
        alignItems: 'center'
    },
    loadingIcon: {
        width: '20px',
        height: '20px',
        margin: '0 10px'
    },
    error: {
        color: 'red'
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px'
    },
    input: {
        flex: 1,
        padding: '15px',
        marginRight: '10px',
        borderRadius: '25px',
        border: '1px solid #ddd',
        fontSize: '12px'
    },
    button: {
        padding: '10px 25px',
        borderRadius: '25px',
        border: 'none',
        backgroundColor: 'rgb(27 188 234)',
        color: 'white',
        cursor: 'pointer',
        fontSize: '12px',
        marginLeft: '10px'
    },
    cancelButton: {
        padding: '10px 25px',
        borderRadius: '25px',
        border: 'none',
        backgroundColor: 'rgb(255 69 58)',
        color: 'white',
        cursor: 'pointer',
        fontSize: '12px',
        marginLeft: '10px'
    },
    suggestionsContainer: {
        display: 'flex',
        flexFlow: 'nowrap',
        justifyContent: 'space-around',
        marginTop: '10px',
    },
    suggestionBubble: {
        padding: '10px 15px',
        borderRadius: '25px',
        backgroundColor: '#fd9a6f',
        color: 'white',
        cursor: 'pointer',
        fontSize: '10px',
        margin: '2px',
        textAlign: 'center'
    },

    '@media (max-width: 480px)': {
        suggestionsContainer: {
            flexFlow: 'column',
        }
    }
};

// Adicionando animação CSS
const stylesGlobal = `
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .loading {
        animation: spin 1s linear infinite;
    }
`;

// Adicionando o estilo global ao documento
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = stylesGlobal;
document.head.appendChild(styleSheet);

export default GrantsChatbot;
