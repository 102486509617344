import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/logogrants.webp';
import arrow from '../assets/btn-arrow.svg';
import '../assets/css/header.css'; // Novo estilo de header
import { useAuth } from '../AuthContext'; // Certifique-se de ter esse import

const Header = () => {
  const { isAuthenticated, logout } = useAuth(); // Use seu hook personalizado
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header-new">
      <div className="header-logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
        {/* Botão de menu hambúrguer para telas menores */}
        <button className="menu-toggle" onClick={toggleMenu}>
          {/* Use classes CSS para criar os ícones */}
          <div className={`menu-icon ${menuOpen ? 'open' : ''}`}></div>
        </button>
      </div>
      <nav className={`header-nav ${menuOpen ? 'open' : ''}`}>
      
    
      
      <Link to="/GrantsChatbot">GrantsChatbot</Link>
        <Link to="/Filter_Grants">Filter_Grants</Link>
        <Link to="/">Recomendation grants</Link>
          {/*   <Link to="/ai-assistance">AI Assistance</Link>
        <Link to="/pricing">Pricing</Link>
          <Link to="/grant-database">Grant Database</Link>
   <Link to="/blog">Blog</Link>  */}  
        {isAuthenticated ? (
          <button onClick={logout} className="btn-arrow">
            Logout
          </button>
        ) : (
          <>
            <Link to="/login" className="btn-arrow">
              Login <img src={arrow} alt="arrow" />
            </Link>
            <Link to="/signup">Sign Up</Link>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
