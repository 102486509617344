import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import config from '../config';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Button,
  Chip,
  Divider,
  Paper,
  Pagination,
  Drawer,
  Box,
  Stack,
  Popover,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { motion } from 'framer-motion';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SortIcon from '@mui/icons-material/Sort';
import '../assets/css/RecommendationsGrants.css';

/* Import Recharts components */
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
} from 'recharts';


axios.defaults.headers['Cache-Control'] = 'no-cache';
axios.defaults.headers['Pragma'] = 'no-cache';
axios.defaults.headers['Expires'] = '0';



const categoryOptions = [
  'Individual Grants',
  'Business Grants',
  'Education & Training Grants',
  'Housing & Community Development',
  'Health & Wellness Grants',
  'Arts & Environmental Grants',
];

const beneficiaryOptions = [
  'Children',
  'Youth',
  'Adults',
  'Seniors',
  'Low Income',
  'Disabled Individuals',
];

const subcategoryOptions = {
  'Individual Grants': [
    'Financial Assistance',
    'Scholarships and Fellowships',
    'Personal Development',
    'Emergency Relief',
    'Career Advancement',
    'Special Needs Funding',
  ],
  'Business Grants': [
    'Startup Grants',
    'Small Business Innovation',
    'Minority-Owned Business Grants',
    'Women-Owned Business Grants',
    'Veteran-Owned Business Grants',
    'Technology and Innovation Funding',
  ],
  'Education & Training Grants': [
    'K-12 Education Grants',
    'Higher Education Grants',
    'Vocational Training',
    'Research Funding',
    'Professional Development',
    'STEM Education Grants',
  ],
  'Housing & Community Development': [
    'Affordable Housing',
    'Urban Development',
    'Rural Development',
    'Homelessness Prevention',
    'Infrastructure Grants',
    'Community Revitalization',
  ],
  'Health & Wellness Grants': [
    'Mental Health Support',
    'Healthcare Access',
    'Disability Assistance',
    'Public Health Initiatives',
    'Wellness Programs',
    'Nutrition and Fitness',
  ],
  'Arts & Environmental Grants': [
    'Performing Arts Funding',
    'Visual Arts Grants',
    'Cultural Heritage Preservation',
    'Environmental Conservation',
    'Renewable Energy Grants',
    'Eco-Friendly Innovation',
  ],
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA336A', '#9933FF'];

const RecommendationsGrants = () => {
  const [allGrants, setAllGrants] = useState([]); // Stores all grants
  const [grants, setGrants] = useState([]); // Stores filtered grants
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [answers, setAnswers] = useState([]);
  const [filters, setFilters] = useState({});
  const [filteredGrants, setFilteredGrants] = useState([]);
  const [selectedGrant, setSelectedGrant] = useState(null);

  const grantsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);

  const navigate = useNavigate();
  const { user } = useAuth();

  const [filteredStatistics, setFilteredStatistics] = useState({});
  const [overallStatistics, setOverallStatistics] = useState({});
  const [sortOption, setSortOption] = useState('post_date_desc');

  const sortOptions = [
    { value: 'post_date_desc', label: 'Newest First' },
    { value: 'post_date_asc', label: 'Oldest First' },
    { value: 'agency_name_asc', label: 'Agency A-Z' },
    { value: 'agency_name_desc', label: 'Agency Z-A' },

    { value: 'eligible_applicants_asc', label: 'Eligible Applicants A-Z' },
    { value: 'eligible_applicants_desc', label: 'Eligible Applicants Z-A' },
  ];

  // Estados para os Popovers dos novos filtros
  const [agencyFilterAnchorEl, setAgencyFilterAnchorEl] = useState(null);
  const [eligibleFilterAnchorEl, setEligibleFilterAnchorEl] = useState(null);

  // Extrai valores únicos para os novos filtros
  const uniqueAgencyNames = useMemo(
    () => [...new Set(grants.map((grant) => grant.agency_name))],
    [grants]
  );

  const uniqueEligibleApplicants = useMemo(
    () => [...new Set(grants.map((grant) => grant.eligible_applicants))],
    [grants]
  );

  useEffect(() => {
    const initialize = async () => {
      if (user) {
        const token = await fetchToken();
        if (token) {
          await fetchUserChoices(token);
        }
      } else {
        navigate('/login');
      }
    };
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, navigate]);

  useEffect(() => {
    if (filteredGrants.length > 0) {
      setFilteredStatistics(computeStatistics(filteredGrants));
    } else {
      setFilteredStatistics({ totalGrants: 0 }); // Define o valor padrão caso não existam resultados
    }
  }, [filteredGrants]); // Monitora alterações em filteredGrants

  const fetchToken = async () => {
    const email = config.default_user_email;
    const password = config.defaultUserPassword;
  
  //  console.log('Payload for token request:', { email, password });
  
    try {
      const response = await axios.post(`${config.apiHost}/api/auth/token`, {
        email,
        password,
      }); 
      const { access_token } = response.data;
      localStorage.setItem('access_token', access_token);
      return access_token;
    } catch (error) {
      console.error('Error fetching token:', error.response?.data || error);
      return null;
    }
  };
  

  const fetchUserChoices = async (token) => {
    try {
      const response = await axios.get(
        `${config.apiHost}/api/auth/user_choices/${user.email}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const choices = response.data.choices || [];
      //console.log('Fetched Choices:', choices);
      setAnswers(choices);
      setFiltersFromChoices(choices);
      if (choices.length > 0) {
        await fetchGrants(choices, token);
      } else {
        setGrants([]);
        setFilteredGrants([]);
        setFilteredStatistics({});
        setOverallStatistics({});
      }
    } catch (error) {
      if (error.response?.status === 401) {
        navigate('/login');
      } else {
        console.error('Error fetching user choices:', error);
      }
    }
  };
  
  const setFiltersFromChoices = (choices) => {
    const filtersObj = {};
    choices.forEach((choice) => {
      const key = mapQuestionToKey(choice.question_id);
      filtersObj[key] = normalizeAnswerValue(choice.answer_value);
    });
    setFilters(filtersObj);
   // console.log('Applied Filters:', filtersObj);
  };

  const mapQuestionToKey = (question) => {
    switch (question) {
      case 'What type of grant are you looking for?':
        return 'category';
      case 'What subcategory best fits your interests?':
        return 'subcategory';
      case 'Who is the primary audience or beneficiary for the grant?':
        return 'beneficiary';
      default:
        return question.toLowerCase().replace(/\s+/g, '_');
    }
  };

  const normalizeAnswerValue = (value) => value.replace(/_/g, ' ');

  const fetchGrants = async (queries, token) => {
    try {
      setLoading(true);

      const grantcategory = normalizeAnswerValue(
        queries.find(
          (q) => q.question_id === 'What type of grant are you looking for?'
        )?.answer_value || ''
      );

      const beneficiary = normalizeAnswerValue(
        queries.find(
          (q) =>
            q.question_id ===
            'Who is the primary audience or beneficiary for the grant?'
        )?.answer_value || ''
      );

      const response = await axios.post(
        `${config.apiHost}/api/recommendations`,
        { grantcategory, beneficiary },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const { filtered_results, overall_statistics, total_results, message } = response.data;

      console.log(response.data);

      if (message) {
        setError(message);
        setGrants([]);
        setFilteredGrants([]);
        setFilteredStatistics({});
        setOverallStatistics({});
      } else {
       // console.log(filtered_results);
        setAllGrants(filtered_results); // Assuming this is the complete set before any additional client-side filtering
        setGrants(filtered_results);
        setFilteredGrants(filtered_results);
        setFilteredStatistics(computeStatistics(filtered_results));
        setOverallStatistics(overall_statistics); // Set overall statistics from API response
      }
    } catch (error) {
      setError('Error fetching grants.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Nova useEffect para aplicar filtros e ordenar
  useEffect(() => {
    let filtered = [...grants];

    // Aplicar filtros existentes
    if (filters.category) {
      filtered = filtered.filter(grant => grant.grant_category === filters.category);
    }

    if (filters.subcategory) {
      filtered = filtered.filter(grant => grant.grant_subcategory === filters.subcategory);
    }

    if (filters.beneficiary) {
      filtered = filtered.filter(grant => grant.beneficiary === filters.beneficiary);
    }

    // Aplicar novos filtros
    if (filters.agency_name) {
      filtered = filtered.filter(grant => grant.agency_name === filters.agency_name);
    }

    if (filters.eligible_applicants) {
      filtered = filtered.filter(grant => grant.eligible_applicants === filters.eligible_applicants);
    }

    // Aplicar ordenação
    switch (sortOption) {
      case 'post_date_asc':
        filtered.sort(
          (a, b) => new Date(a.post_date) - new Date(b.post_date)
        );
        break;
      case 'post_date_desc':
        filtered.sort(
          (a, b) => new Date(b.post_date) - new Date(a.post_date)
        );
        break;
      case 'agency_name_asc':
        filtered.sort((a, b) => a.agency_name.localeCompare(b.agency_name));
        break;
      case 'agency_name_desc':
        filtered.sort((a, b) => b.agency_name.localeCompare(a.agency_name));
        break;
      case 'eligible_applicants_asc':
        filtered.sort((a, b) => {
          const eligibleA = a.eligible_applicants || '';
          const eligibleB = b.eligible_applicants || '';
          return eligibleA.localeCompare(eligibleB);
        });
        break;
      case 'eligible_applicants_desc':
        filtered.sort((a, b) => {
          const eligibleA = a.eligible_applicants || '';
          const eligibleB = b.eligible_applicants || '';
          return eligibleB.localeCompare(eligibleA);
        });
        break;
      default:
        break;
    }

    setFilteredGrants(filtered);
    setCurrentPage(1); // Reset para a primeira página ao mudar a ordenação
  }, [grants, filters, sortOption]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
    if (key === 'category') {
      setFilters((prev) => ({
        ...prev,
        subcategory: '',
      }));
    }
  };

  const saveUserChoices = async (updatedFilters, token) => {
    try {
      const payload = Object.keys(updatedFilters).map((key) => {
        let question_id = '';
        switch (key) {
          case 'category':
            question_id = 'What type of grant are you looking for?';
            break;
          case 'subcategory':
            question_id = 'What subcategory best fits your interests?';
            break;
          case 'beneficiary':
            question_id =
              'Who is the primary audience or beneficiary for the grant?';
            break;
          case 'agency_name':
            question_id = 'Agency Name';
            break;
          case 'eligible_applicants':
            question_id = 'Eligible Applicants';
            break;
          default:
            question_id = key.replace(/_/g, ' ');
        }
        return {
          question_id,
          answer_value: updatedFilters[key].replace(/ /g, '_'),
        };
      });

      const response = await axios.post(
        `${config.apiHost}/api/auth/user_choices/`,
        {
          user_email: user.email,
          choices: payload,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
       // console.log('User choices saved successfully:', response.data);
      } else {
        console.warn('Failed to save user choices:', response);
        setError('Failed to save your preferences. Please try again.');
      }
    } catch (error) {
      console.error('Error saving user choices:', error);
      setError('Error saving your preferences. Please try again.');
    }
  };

  const applyFilters = async () => {
    setError(null);
    const token = localStorage.getItem('access_token');
    if (!token) {
      setError('User is not authenticated.');
      return;
    }
  
    try {
      // Salva as preferências do usuário
      await saveUserChoices(filters, token);
  
      // Chama fetchGrants diretamente com os filtros atualizados
      await fetchGrantsWithFilters(filters, token);
  
      // Fecha o Drawer de filtros
      setShowFilters(false);
    } catch (err) {
      console.error('Error applying filters:', err);
      setError('Error applying filters. Please try again.');
    }
  };


  const fetchGrantsWithFilters = async (currentFilters, token) => {
    try {
      setLoading(true);
  
      const grant_category = currentFilters.category || '';
      const grant_subcategory = currentFilters.subcategory || '';
  
      const response = await axios.post(
        `${config.apiHost}/api/recommendations`,
        { grant_category, grant_subcategory },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      console.log( response.data)
      const { filtered_results, overall_statistics, total_results, message } = response.data;
  
      if (message) {
        setError(message);
        setGrants([]);
        setFilteredGrants([]);
        setFilteredStatistics({});
        setOverallStatistics({});
      } else {
        setAllGrants(filtered_results);
        setGrants(filtered_results);
        setFilteredGrants(filtered_results);
        setFilteredStatistics(computeStatistics(filtered_results));
        setOverallStatistics(overall_statistics);
      }
    } catch (error) {
      setError('Error fetching grants.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const computeStatistics = (grantsList) => {

    const totalGrants = grantsList.length;

    const grantsPerCategory = grantsList.reduce((acc, grant) => {
      const category = grant.grant_category || 'Unknown';
      acc[category] = (acc[category] || 0) + 1;
      return acc;
    }, {});

    const grantsPerBeneficiary = grantsList.reduce((acc, grant) => {
      const beneficiary = grant.beneficiary || 'Unknown';
      acc[beneficiary] = (acc[beneficiary] || 0) + 1;
      return acc;
    }, {});

    const grantsOpenedThisMonth = grantsList.filter((grant) => {
      const postDate = new Date(grant.post_date);
      const now = new Date();
      return (
        postDate.getMonth() === now.getMonth() &&
        postDate.getFullYear() === now.getFullYear()
      );
    }).length;

    return {
      totalGrants,
      grantsPerCategory,
      grantsPerBeneficiary,
      grantsOpenedThisMonth, // New statistic
    };
  };

  useEffect(() => {
    if (filteredGrants.length > 0) {
      setFilteredStatistics(computeStatistics(filteredGrants));
    } else {
      setFilteredStatistics({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredGrants]);

  // Função de Legenda Personalizada para PieChart
  const renderCustomLegend = (props) => {
    const { payload } = props;

    // Mapeia os dados para facilitar o acesso aos valores
    const dataMap = Object.entries(overallStatistics.grants_per_category_overall || {}).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    return (
      <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
            <span style={{
              backgroundColor: entry.color,
              width: 10,
              height: 10,
              display: 'inline-block',
              marginRight: 8,
            }}></span>
            <span>{entry.value} ({dataMap[entry.value] || 0})</span>
          </li>
        ))}
      </ul>
    );
  };

  // Função de Legenda Personalizada para BarChart
  const renderCustomLegendBeneficiary = (props) => {
    const { payload } = props;

    // Mapeia os dados para facilitar o acesso aos valores
    const dataMap = Object.entries(overallStatistics.grants_per_beneficiary_overall || {}).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});

    return (
      <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
        {payload.map((entry, index) => (
          <li key={`item-beneficiary-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}>
            <span style={{
              backgroundColor: entry.color,
              width: 10,
              height: 10,
              display: 'inline-block',
              marginRight: 8,
            }}></span>
            <span>{entry.value} ({dataMap[entry.value] || 0})</span>
          </li>
        ))}
      </ul>
    );
  };

  const renderStatistics = () => {
    return (
      <Paper elevation={3} className="statistics-paper">
        {/* Card "Total Grants Available" Dentro do statistics-paper */}
        <Divider sx={{ mt: 2 }} /> {/* Adiciona margin-top: 16px */}
        <Typography variant="h6" color="textSecondary" gutterBottom>
          <center> Total Grants Available</center>
        </Typography>
        <Typography variant="h4" color="primary">
          <center>{overallStatistics.total_grants  || 0}</center>
        </Typography>

        <Divider sx={{ mt: 2 }} /> {/* Adiciona margin-top: 16px */}

        {/* Seção de Estatísticas Gerais */}
        <Typography variant="h5" gutterBottom>
          Overall Statistics
        </Typography>

        {/* Grants per Category - Pie Chart */}
        <Typography variant="h6" gutterBottom>
          Grants per Category (Overall)
        </Typography>

        <ResponsiveContainer width="100%" height={500}>
          <PieChart>
            <Pie
              data={Object.entries(overallStatistics.grants_per_category_overall || {}).map(
                ([key, value]) => ({ name: key, value })
              )}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={100}
              // Removendo o atributo 'label' para eliminar os rótulos externos
            >
              {Object.entries(overallStatistics.grants_per_category_overall || {}).map(
                ([key, value], index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                )
              )}
            </Pie>
            <Tooltip />
            <Legend content={renderCustomLegend} />
          </PieChart>
        </ResponsiveContainer>
      </Paper>
    );
  };

  const renderChoices = () => {
    return (
      <Box className="choices-box">
        <Typography variant="h6" gutterBottom>
          Your Preferences
        </Typography>
        <Box className="choices-container">
          {filters.category && (
            <Chip
              label={`Category: ${filters.category}`}
              color="primary"
              variant="outlined"
            />
          )}
          {filters.subcategory && (
            <Chip
              label={`Subcategory: ${filters.subcategory}`}
              color="secondary"
              variant="outlined"
            />
          )}
         
          {filters.agency_name && (
            <Chip
              label={`Agency: ${filters.agency_name}`}
              color="info"
              variant="outlined"
            />
          )}
          {filters.eligible_applicants && (
            <Chip
              label={`Eligible Applicants: ${filters.eligible_applicants}`}
              color="warning"
              variant="outlined"
            />
          )}
        </Box>
      </Box>
    );
  };

  const renderTotal_filter = () => {
    return (
      <Box
        className="choices-box"
        style={{
          padding: '12px', // Reduz o espaçamento interno
          display: 'flex', // Alinha os itens horizontalmente
          flexDirection: 'column', // Mantém os itens na vertical
          alignItems: 'center', // Centraliza horizontalmente
          justifyContent: 'center', // Centraliza verticalmente
          gap: '4px', // Espaçamento entre os elementos
        }}
      >
        <Typography
          variant="h6"
          color="textSecondary"
          style={{ marginBottom: '4px' }} // Reduz o espaço abaixo do título
        >
          Total Grants:
        </Typography>
        <Typography
          variant="h4"
          color="primary"
          style={{ margin: 0 }} // Remove margens extras
        >
          {filteredStatistics.totalGrants || 0}
        </Typography>
      </Box>
    );
  };

  const renderGrantsList = () => {
    return (
      <div className="grants-list">
        <Grid container spacing={4}>
          {filteredGrants
            .slice((currentPage - 1) * grantsPerPage, currentPage * grantsPerPage)
            .map((grant, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <motion.div whileHover={{ scale: 1.05 }} className="grant-motion">
                  <Card
                    className="grant-card"
                    onClick={() => setSelectedGrant(grant)}
                    elevation={6}
                  >
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {grant.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {grant.agency_name}
                      </Typography>
                      <Chip
                        label={grant.grant_category}
                        color="primary"
                        size="small"
                        className="grant-chip"
                      />
                      
                      <Typography variant="body2" color="textSecondary" className="grant-date">
                        Posted on: {new Date(grant.post_date).toLocaleDateString()}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Closing: {grant.close_date ? new Date(grant.close_date).toLocaleDateString() : 'N/A'}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Award Ceiling: ${grant.award_ceiling || 'N/A'}
                      </Typography>

                      <Typography variant="body2" color="textSecondary">
                        Eligible: {grant.eligible_applicants || 'N/A'}
                      </Typography>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
        </Grid>

        {/* Pagination Controls */}
        <Box className="pagination-controls">
          <Pagination
            count={Math.ceil(filteredGrants.length / grantsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </div>
    );
  };

  const renderGrants = () => {
    return (
      <>
        {loading ? (
          <Box className="loading-container">
            <CircularProgress size={80} thickness={4} />
          </Box>
        ) : error ? (
          <Typography color="error" className="error-message">{error}</Typography>
        ) : filteredGrants.length === 0 ? (
          <Box className="no-data-container">
            <SentimentDissatisfiedIcon className="no-data-icon" />
            <Typography variant="h6">No grants found with the current filters.</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowFilters(true)}
              style={{ marginTop: '1rem' }}
            >
              Update Filters
            </Button>
          </Box>
        ) : (
          renderGrantsList()
        )}
      </>
    );
  };

  const renderCharts = () => {
    return (
      <Box>
        {renderStatistics()}
        {/* Removemos a chamada de renderFilteredStatistics */}
      </Box>
    );
  };

  // Handlers para abrir e fechar os Popovers de filtros
  const handleAgencyFilterClick = (event) => {
    setAgencyFilterAnchorEl(event.currentTarget);
  };

  const handleEligibleFilterClick = (event) => {
    setEligibleFilterAnchorEl(event.currentTarget);
  };

  const handleAgencyFilterClose = () => {
    setAgencyFilterAnchorEl(null);
  };

  const handleEligibleFilterClose = () => {
    setEligibleFilterAnchorEl(null);
  };

  return (
    <div className="recommendations-container">
      <Grid container spacing={2}>
        {/* Coluna Esquerda: Your Preferences */}
        <Grid item xs={12} md={6}>
          {renderChoices()}
        </Grid>

        {/* Coluna Direita: Total Grants Found */}
        <Grid item xs={12} md={6}>
          {renderTotal_filter()}
        </Grid>
      </Grid>

      {/* Container para os botões de filtros e Sort By */}
      <Grid container spacing={2} alignItems="center" style={{ marginTop: '1rem' }}>
        {/* Botão "Update Preferences" */}
        <Grid item>
          <Button
            variant="contained"
            startIcon={<FilterListIcon />}
            onClick={() => setShowFilters(true)}
            color="primary"
          >
            Update Preferences
          </Button>
        </Grid>

       
          {/*   <Grid item>
       Botão de Filtro por Agency
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={handleAgencyFilterClick}
            color="primary"
          >
            Agency
          </Button>
          <Popover
            open={Boolean(agencyFilterAnchorEl)}
            anchorEl={agencyFilterAnchorEl}
            onClose={handleAgencyFilterClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          > *
            <Box p={2} width={250}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Agency Name</InputLabel>
                <Select
                  label="Agency Name"
                  value={filters.agency_name || ''}
                  onChange={(e) => handleFilterChange('agency_name', e.target.value)}
                >
                  <MenuItem value=""><em>All</em></MenuItem>
                  {uniqueAgencyNames.map((agency) => (
                    <MenuItem value={agency} key={agency}>{agency}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Popover>
        </Grid>/}

        {/* Botão de Filtro por Eligible Applicants
        <Grid item>
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            onClick={handleEligibleFilterClick}
            color="primary"
          >
            Eligible
          </Button>
          <Popover
            open={Boolean(eligibleFilterAnchorEl)}
            anchorEl={eligibleFilterAnchorEl}
            onClose={handleEligibleFilterClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box p={2} width={250}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Eligible Applicants</InputLabel>
                <Select
                  label="Eligible Applicants"
                  value={filters.eligible_applicants || ''}
                  onChange={(e) => handleFilterChange('eligible_applicants', e.target.value)}
                >
                  <MenuItem value=""><em>All</em></MenuItem>
                  {uniqueEligibleApplicants.map((eligible) => (
                    <MenuItem value={eligible} key={eligible}>{eligible}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Popover>
        </Grid> */}

        {/* Seletor "Sort By" ocupa o restante do espaço */}
        <Grid item xs>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              label="Sort By"
            >
              {sortOptions.map((option) => (
                <MenuItem value={option.value} key={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Condicional para exibir dados apenas se as preferências estiverem definidas */}
      {loading ? (
        // Indicador de carregamento global
        <Box className="loading-container">
          <CircularProgress size={80} thickness={4} />
        </Box>
      ) : Object.keys(filters).length > 0 ? (
        // Se as preferências estão definidas, exibe estatísticas e lista de grants
        <Grid container spacing={4} style={{ marginTop: '2rem' }}>
          {/* Coluna Esquerda: Estatísticas */}
          <Grid item xs={12} md={4}>
            {renderCharts()}
          </Grid>

          {/* Coluna Direita: Lista de Grants */}
          <Grid item xs={12} md={8}>
            {renderGrants()}
          </Grid>
        </Grid>
      ) : (
        // Se as preferências não estão definidas, exibe apenas a mensagem e o botão para atualizar
        <Box className="no-preferences-container" style={{ marginTop: '2rem', textAlign: 'center' }}>
          <SentimentDissatisfiedIcon style={{ fontSize: 60, color: '#888' }} />
          <Typography variant="h6" gutterBottom>
            No preferences set.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please update your preferences to see grant recommendations.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowFilters(true)}
            style={{ marginTop: '1rem' }}
          >
            Update Preferences
          </Button>
        </Box>
      )}

      {/* Drawer para Filtros */}
      <Drawer
        anchor="right"
        open={showFilters}
        onClose={() => setShowFilters(false)}
      >
        <Box className="filter-drawer" role="presentation" sx={{ width: 300, padding: '1rem' }}>
          <Box className="filter-drawer-header" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Update Preferences</Typography>
            <IconButton onClick={() => setShowFilters(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box className="filter-drawer-content" mt={2}>
            {/* Usando Stack para espaçamento */}
            <Stack spacing={2}>
              <FormControl variant="outlined" fullWidth className="filter-form-control">
                <InputLabel>Category</InputLabel>
                <Select
                  value={filters.category || ''}
                  onChange={(e) => handleFilterChange('category', e.target.value)}
                  label="Category"
                >
                  {categoryOptions.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                fullWidth
                className="filter-form-control"
                disabled={!filters.category}
              >
                <InputLabel>Subcategory</InputLabel>
                <Select
                  value={filters.subcategory || ''}
                  onChange={(e) => handleFilterChange('subcategory', e.target.value)}
                  label="Subcategory"
                >
                  {subcategoryOptions[filters.category]?.map((option) => (
                    <MenuItem value={option} key={option}>
                      {option}
                    </MenuItem>
                  )) || (
                    <MenuItem value="" disabled>
                      No options available
                    </MenuItem>
                  )}
                </Select>
              </FormControl>

          
            </Stack>

            {/* Botões de Aplicar e Limpar Filtros */}
            <Box className="filter-buttons" mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={applyFilters}
                startIcon={<SortIcon />}
                fullWidth
              >
                Apply Filters
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setFilters({});
                  setFilteredGrants(allGrants); // Reset to all grants
                  setFilteredStatistics(computeStatistics(allGrants)); // Reset statistics
                }}
                fullWidth
                style={{ marginTop: '0.5rem' }}
              >
                Clear Filters
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>

      {/* Dialog para Detalhes do Grant Selecionado */}
      {selectedGrant && (
        <Dialog
          open={Boolean(selectedGrant)}
          onClose={() => setSelectedGrant(null)}
          fullWidth
          maxWidth="md"
          className="dialog-container"
        >
          <DialogTitle className="dialog-title">
            {selectedGrant.title}
            <IconButton
              aria-label="close"
              onClick={() => setSelectedGrant(null)}
              className="dialog-close-button"
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers className="dialog-content">
            <Typography variant="subtitle1" gutterBottom>
              <strong>Opportunity ID:</strong> {selectedGrant.opportunity_id}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Agency:</strong> {selectedGrant.agency_name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Category:</strong> {selectedGrant.grant_category}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Subcategory:</strong> {selectedGrant.grant_subcategory}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Beneficiary:</strong> {selectedGrant.beneficiary}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Funding Instrument Type:</strong> {selectedGrant.funding_instrument_type}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Eligible Applicants:</strong> {selectedGrant.eligible_applicants}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Estimated Total Program Funding:</strong> ${selectedGrant.estimated_total_program_funding || 'N/A'}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Expected Number of Awards:</strong> {selectedGrant.expected_number_of_awards || 'N/A'}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Publication Date:</strong> {new Date(selectedGrant.post_date).toLocaleDateString()}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Closing Date:</strong> {selectedGrant.close_date ? new Date(selectedGrant.close_date).toLocaleDateString() : 'N/A'}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              <strong>Description:</strong> {selectedGrant.description}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              href={selectedGrant.url_grantsgov}
              target="_blank"
              rel="noopener noreferrer"
              className="dialog-view-details-button"
            >
              View More Details
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default RecommendationsGrants;
