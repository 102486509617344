const config = {
  apiHost: process.env.REACT_APP_API_HOST || '',
  clientId_paypal: process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
  plan_1_paypal: process.env.REACT_APP_PAYPAL_PLAN_1 || '',
  plan_2_paypal: process.env.REACT_APP_PAYPAL_PLAN_2 || '',
  google_client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
  default_user_email: process.env.REACT_APP_DEFAULT_USER_EMAIL || '',
  defaultUserPassword: process.env.REACT_APP_DEFAULT_USER_PASSWORD || '',
};

export default config;
